<template>
  <PerusahaanForm mode="Tambah" module="Perusahaan"> </PerusahaanForm>
</template>

<script>
import PerusahaanForm from './form';

const PerusahaanAdd = {
  name: 'PerusahaanAdd',
  components: { PerusahaanForm },
};

export default PerusahaanAdd;
</script>
